

const SideMenu = ({ 
  newChat, 
  currentType, 
  setCurrentType, 
  triggerType, 
  setTriggerType,
  placeholder,
  setPlaceholder,
  isDisabled,
  setIsDisabled,
  setEnterKeyToggle,
  enterKeyToggle,
   }) => {
  
    const types = ['General', 'Validation Rule', 'Formula Field', 'Custom Link URL', 'Visualforce Page URL', 'SOQL', 'Trigger', 'Class', 'Test Class', 'Lightning Web Component', 'Visualforce Page', 'Interview Coach'];
  
    const resetType = () => {
    setIsDisabled(false);
    setCurrentType("General");
    setTriggerType("");
    setPlaceholder("AI Force ready to go!");
    newChat();
  }
   
    const handleEnterKeyToggle = () => {
    setEnterKeyToggle(!enterKeyToggle);
    console.log(enterKeyToggle);
  }

  const handleCurrentTypeChange = (e) => {
    setCurrentType(e.target.value);
    switch (e.target.value) {
      
      case "Validation Rule":
        setPlaceholder("Time for a Validation Rule!");
        break;
      case 'Formula Field':
        setPlaceholder("Formula Field - Hit me!");
        break;
      case 'Custom Link URL':
          setPlaceholder("Looking for a URL? - Let's go!");
          break;
      case 'Visualforce Page URL':
          setPlaceholder("I'm ready, a URL link to a Visaulforce page.");
          break;
      case 'SOQL':
            setPlaceholder("Ok, let's SELECT fields FROM an object...!");
            break;
      case 'Trigger':
              setPlaceholder("Triggers! My favorite. Guide me!");
              break;
      case 'Class':
                setPlaceholder("Classes! Let's do this!");
                break;
      case 'Test Class':
                  setPlaceholder("What class shall we test today?");
                  break;
      case 'Lightning Web Component':
                  setPlaceholder("A Lightning Web Component? Ok!");
                  break;
      case 'Visualforce Page':
                  setPlaceholder("I like Visualforce! What do you need?");
                  break;
      case 'Interview Coach':
                  setPlaceholder("Give me your First name and the position applied for!");
                  break;
      // Add more cases for each type and its matching phrase
      default:
        setPlaceholder("AI Force ready to go!");
    }
  }

  return (
    
    
    <aside className="sidemenu">
      <div className="side-menu-button" onClick={resetType}>
        <span>+</span>
        New Chat
      </div>
      <br></br>
      <span>
        <div className="dropdown" >
          <select className="dropdown" value={currentType} disabled={isDisabled} multiple={false} onChange={handleCurrentTypeChange}
          >
            {types.map((type) => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
        </div>
        <div>.</div>
          
        {currentType === 'Trigger' && (
  <div className="checkbox-group">
    <label style={{ display: 'block' }}>
      <input type="checkbox" value="Before Insert" checked={triggerType.includes('Before Insert')} onChange={(e) => {
        setTriggerType(prevTypes => {
          if (e.target.checked) {
            return [...prevTypes, 'Before Insert'];
          } else {
            return prevTypes.filter(type => type !== 'Before Insert');
          }
        });
      }} />
      Before Insert
    </label>
    <label style={{ display: 'block' }}>
      <input type="checkbox" value="Before Update" checked={triggerType.includes('Before Update')} onChange={(e) => {
        setTriggerType(prevTypes => {
          if (e.target.checked) {
            return [...prevTypes, 'Before Update'];
          } else {
            return prevTypes.filter(type => type !== 'Before Update');
          }
        });
      }} />
      Before Update
    </label>
    <label style={{ display: 'block' }}>
      <input type="checkbox" value="Before Delete" checked={triggerType.includes('Before Delete')} onChange={(e) => {
        setTriggerType(prevTypes => {
          if (e.target.checked) {
            return [...prevTypes, 'Before Delete'];
          } else {
            return prevTypes.filter(type => type !== 'Before Delete');
          }
        });
      }} />
      Before Delete
    </label>
    <label style={{ display: 'block' }}>
      <input type="checkbox" value="After Insert" checked={triggerType.includes('After Insert')} onChange={(e) => {
        setTriggerType(prevTypes => {
          if (e.target.checked) {
            return [...prevTypes, 'After Insert'];
          } else {
            return prevTypes.filter(type => type !== 'After Insert');
          }
        });
      }} />
      After Insert
    </label>
    <label style={{ display: 'block' }}>
      <input type="checkbox" value="After Update" checked={triggerType.includes('After Update')} onChange={(e) => {
        setTriggerType(prevTypes => {
          if (e.target.checked) {
            return [...prevTypes, 'After Update'];
          } else {
            return prevTypes.filter(type => type !== 'After Update');
          }
        });
      }} />
      After Update
    </label>
    <label style={{ display: 'block' }}>
      <input type="checkbox" value="After Delete" checked={triggerType.includes('After Delete')} onChange={(e) => {
        setTriggerType(prevTypes => {
          if (e.target.checked) {
            return [...prevTypes, 'After Delete'];
          } else {
            return prevTypes.filter(type => type !== 'After Delete');
          }
        });
      }} />
      After Delete
    </label>
    
    <label style={{ display: 'block' }}>
      <input type="checkbox" value="After Undelete" checked={triggerType.includes('After Undelete')} onChange={(e) => {
        setTriggerType(prevTypes => {
          if (e.target.checked) {
            return [...prevTypes, 'After Undelete'];
          } else {
            return prevTypes.filter(type => type !== 'After Undelete');
          }
        });
      }} />
      After Undelete
    </label>
  </div>
)}
        </span>
        <span className="side-menu-container">
        {currentType === 'Validation Rule' && (
      <div className="info" style={{ textAlign: 'center' }}>
       
       <a href="http://trailhead.salesforce.com/content/learn/modules/point_click_business_logic" target="_blank" rel="noreferrer" >Trailhead Intro</a>
       <br />
       <a href="https://help.salesforce.com/s/articleView?id=sf.fields_about_field_validation.htm&type=5" target="_blank" rel="noreferrer" >Overview Help Article</a>
       <br />
       <a href="https://help.salesforce.com/s/articleView?id=sf.customize_functions.htm&type=5" target="_blank" rel="noreferrer" >Operators and Functions Help Article</a>

      </div>
    )}

    {currentType === 'Formula Field' && (
      <div className="info" style={{ textAlign: 'center' }}>
        <a href="http://trailhead.salesforce.com/content/learn/modules/point_click_business_logic" target="_blank" rel="noreferrer" >Trailhead Intro</a>
       <br />
       <a href="https://help.salesforce.com/s/articleView?id=sf.custom_links_constructing.htm&type=5" target="_blank" rel="noreferrer" >Custom Button/Links Help Article</a>
       <br />
       <a href="https://help.salesforce.com/s/articleView?id=sf.tips_on_building_formulas.htm&type=5" target="_blank" rel="noreferrer" >Tips Help Article</a>
       <br />
       <a href="https://help.salesforce.com/s/articleView?language=en_US&id=sf.customize_functions.htm&type=5" target="_blank" rel="noreferrer" >Operators and Functions Help Article</a>
       <br />
       <a href="https://help.salesforce.com/s/articleView?language=en_US&id=sf.customize_formula_best_practices.htm&type=5" target="_blank" rel="noreferrer" >Best Practice Help Article</a>
       <br />

        </div>
    )}

{currentType === 'Custom Link URL' && (
      <div className="info" style={{ textAlign: 'center' }}>
        
       <a href="https://help.salesforce.com/s/articleView?id=sf.custom_links_constructing.htm&type=5" target="_blank" rel="noreferrer" >Custom Button/Links Help Article</a>
       <br />
       

        </div>
    )}



        {currentType === 'SOQL' && (
          <div className="info" style={{ textAlign: 'center' }}>
           <strong>Some SOQL Terms</strong>
           <br />
            SELECT
           <br />
            FROM
           <br />
            WHERE
            <br />
            <br />
            Starts With 
            <br />
            Ends With
            <br />
            Contains
            <br></br>
            In
            <br></br>
            Not In
            <br></br>
            Includes
            <br></br>
            Excludes
            <br></br>
            <br></br>
            Equals
            <br></br>
            Not Equal To 
            <br></br>
            Greater Than
            <br></br>
            Less Than
            <br></br>
            Greater Than or Equal To
            <br></br>
            Less Than or Equal To
            <br></br>
            <br></br>
            MAX
            <br></br>
            MIN
            <br></br>
            SUM
            <br></br>
            AVG
            <br></br>
            COUNT
            <br></br>
            COUNT DISTINCT
            <br></br>
            <br></br>
            Limit
            <br></br>
            Nulls First
            <br></br>
            Nulls Last
            <br></br>
            Order By
            <br></br>
            Group By
            <br></br>
            Ascending
            <br></br>
            Descending
            <br></br>
            <br></br>
            <a href="https://trailhead.salesforce.com/content/learn/modules/soql-for-admins/get-started-with-soql-queries" target="_blank" rel="noreferrer" >Getting Started With SOQL</a>
            <br></br>
            <a href="https://www.salesforceben.com/salesforce-workbench/" target="_blank" rel="noreferrer" >Intro To Workbench</a>
            <br></br>
            <a href="https://workbench.developerforce.com/select.php" target="_blank" rel="noreferrer" >Salesforce Workbench</a>
            <br></br>
            <a href="https://trailhead.salesforce.com/content/learn/modules/developer_console/developer_console_intro" target="_blank" rel="noreferrer" >Getting Started - Developer Console</a>
            <br></br>
            <a href="https://blog.bessereau.eu/assets/pdfs/salesforce_soql_sosl.pdf" target="_blank" rel="noreferrer" >Developer Reference Guide</a>

          </div>
        )}

{currentType === 'Trigger' && (
      <div className="info" style={{ textAlign: 'center' }}>
        <br />
        <strong>Context Variables</strong>
           <br />
            isExecuting
           <br />
            isInsert
           <br />
            isUpdate
            <br />
            isDelete
            <br />
            isBefore
            <br />
            isAfter
            <br />
            isUndelete
            <br />
            new
            <br />
            newMap
            <br />
            old
            <br />
            oldMap
            <br />
            operationType
            <br />
            size
            <br />
            <br />
       <a href="https://trailhead.salesforce.com/content/learn/modules/apex_triggers" target="_blank" rel="noreferrer" >Intro to Triggers - Trailhead</a>
       <br />
       <a href="https://developer.salesforce.com/docs/atlas.en-us.apexcode.meta/apexcode/apex_triggers.htm" target="_blank" rel="noreferrer" >Apex Developer Guide - Triggers</a>
       <br />

        </div>
    )}
        </span>
        <span className="side-menu-container-footer">
      <div className="switchtext">Enter Key to Send</div>
        
        <div >
          <label className="switch"  >
        <input type="checkbox" defaultChecked onClick={handleEnterKeyToggle}></input>
          <span className="slider round" ></span>
          </label>
        
        
       </div>
       <span className="side-menu-container-footer">
       <div className="Main-logo">
      <img src="./logo192.png" width={"160px"} alt="Logo" />
      </div>
      <div className="info">
        GPT-3.5
      </div>
      </span>
      
      </span> 

     
    </aside>
    
  );
};



//const Button = ({ onClick, text }) =>
 // <div 
 //   className="button-picker" 
 //   onClick={onClick}>
 //   {text}
 // </div>

export default SideMenu