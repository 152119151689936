import { useState  } from 'react'; 


const auto_grow = (element) => {
  element.style.height = "5px";
  element.style.height = (element.scrollHeight)+"px";
}
// Primary Chat Window



const ChatBox = ({ chatLog, setChatInput, handleSubmit, handleRetry, stepBack, chatInput, placeholder, handleEnterToggle, onEnterKeyDown, enterKeyToggle}) =>
  
  

  <section className="chatbox"  >
    <div className="chat-log"  >
      {chatLog.map((message, index) => (
        <ChatMessage key={index} message={message} />
      ))}
    </div>

    
    <div className="chat-input-holder" >
        <span style={{alignSelf: 'flex-end'    }}>
          <div style={{alignSelf: 'center', margin: "10px"  }}>
          
          </div>
        </span>

      <form className="form" onSubmit={handleSubmit} >
        
      <div style={{alignSelf: 'flex-end'  }}>
        <button id="stepback" className="submit" type="button" onClick={stepBack}>
          <img src='rewind.png' alt='stepback'  />
            <div >Rewind</div>
          </button> 
        
        </div>
        
        <textarea

        
        onKeyDown={(e) => {
          if (e.key === 'Enter'){
            if (enterKeyToggle) { 
              document.getElementById("button").click()
           }
          }
        }}
      rows={Math.min(chatInput.split('\n').length, 8)}
      value={chatInput}
      onChange={(e) => setChatInput(e.target.value)}
      onInput={(e) => auto_grow(e.target)}
      className="chat-input-textarea"
      style={{resize: 'none'
      }} 
      maxLength={5000}
       placeholder={placeholder}
    > </textarea>
      
        <span style={{alignSelf: 'flex-end', float: "right" }}>
          
          <div style={{display: 'flex',  gap: "5px" }}>        
            <button id="button" className="submit" type="submit" >
              <img src='Submit.png' alt='send'  ></img>  
              <div >Send</div>            
            </button>    
            
            <button id="retry" className="submit" type="button" onClick={handleRetry} >
            <img src='retry.png' alt='retry'  ></img>
            <div >Retry</div>
          </button>
          
          </div> 

        </span>
      </form>
    </div>
  </section>


// Individual Chat Message
const ChatMessage = ({ message }) => {
  
  const [showCopy, setShowCopy] = useState(false);

  const handleCopy = () => {
    let messageToCopy = message.message.trim();
    // Check if the message starts with \n or \n\n
    if (messageToCopy.startsWith('\n\n')) {
      messageToCopy = messageToCopy.substring(2);
    } else if (messageToCopy.startsWith('\n')) {
      messageToCopy = messageToCopy.substring(1);
    }
    // Check if the message ends with \n or \n\n
    if (messageToCopy.endsWith('\n\n')) {
      messageToCopy = messageToCopy.substring(0, messageToCopy.length - 2);
    } else if (messageToCopy.endsWith('\n')) {
      messageToCopy = messageToCopy.substring(0, messageToCopy.length - 1);
    }
    navigator.clipboard.writeText(messageToCopy);
    document.querySelector('.copy-button').classList.add('clicked');
  };
  
  
  
  
  return (

    <div className={`chat-message ${message.user === "AIFORCE" && "AIFORCE"}`} onMouseEnter={() => setShowCopy(true)} onMouseLeave={() => setShowCopy(false)}>
      {showCopy && message.user === "AIFORCE" && (
        <button className="copy-button" onClick={handleCopy}>
          <img src="copy-icon.png" width="20px" height="20px" alt="copy" />
        </button>
      )}     
      
      
      <div className="chat-message-center">
        <div className={`avatar ${message.user === "AIFORCE" && "AIFORCE"}`}>
          {message.user === "AIFORCE" || message.message === 
              'AI Force Assistant at your service!'  
              ? <img className='aiforce_logo' width="1px" height="1px"  src="AiForce_Icon.png" alt="AIForce Icon" /> 
              : <img className='aiforce_logo' src="Person.png" alt="Person Icon" /> }
        </div>
     
        <div className="message">
          <pre>
          {message.message}
          </pre>
        </div>
 
      </div>
    </div>
  )
}

export default ChatBox