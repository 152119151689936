import './normal.css';
import './App.css';
import { useState, } from 'react';  
import SideMenu from './SideMenu'
import ChatBox from './ChatBox'

function App() {
  const [chatInput, setChatInput] = useState("");
  const [types, setTypes] = useState([])
  const [currentType, setCurrentType] = useState('General');
  const [triggerType, setTriggerType] = useState('');
  const [chatLog, setChatLog] = useState([]);
  const [placeholder, setPlaceholder] = useState("AI Force ready to go!");
  const [isDisabled, setIsDisabled] = useState(false);
  const [enterKeyToggle, setEnterKeyToggle] = useState(true);
  
  function onEnterKeyDown(e) {
    if (enterKeyToggle) { 
       document.getElementById("button").click()
    }
  }   

  const newChat = () => {
    setChatLog([]);
    setChatInput(""); 
  }

  function disableSideMenu(){
    setIsDisabled(true);
  }

  const handleRetry = () => {
    if (chatLog.length === 0 || chatLog.length === 1) {
      return; } 
      else {
    handleSubmit(new Event('retry')); 
      }
  }
 
  const stepBack = () => {
    if (chatLog.length === 0 || chatLog.length === 1) {
      return; }
      else {
    const stepBackChatLog = [...chatLog];
    setChatInput(stepBackChatLog[stepBackChatLog.length-2].message);
     stepBackChatLog.splice(-2, 2);
    setChatLog(stepBackChatLog);
    }
  }

  async function handleSubmit(e) {  
    e.preventDefault();
    if (chatInput === "" && e.type !== "retry") {
      return;
      } 
      else {  
    
      disableSideMenu();
    
      const responseLoading = document.querySelector(".chatbox");
      const submitLoading = document.querySelectorAll(".submit");

      const submitButton = document.getElementById("button");
      const stepBackButton = document.getElementById("stepback");
      const retryButton = document.getElementById("retry");
  
    
      responseLoading.classList.add("chatbox--loading");
      submitLoading.forEach(function(item) {
        item.classList.replace("submit", "submitdisabled");  
        });

      stepBackButton.disabled = true;
      retryButton.disabled = true; 
      submitButton.disabled = true;
   

        //Add the new message to the chatLog
      let chatLogNew = [...chatLog, { user: "me", message: `${chatInput}`} ]
      setChatInput(" ");
      setChatInput("");
      setChatLog(chatLogNew)

        //if retry button is pressed, remove the last message from chatLog
      if (e.type === "retry") {
      chatLogNew.splice(-2, 2);
      setChatLog(chatLogNew);
        }
    
        //Join all messages in chatLog to one string
      const messages = chatLogNew.map((message) => message.message).join("\n")  
   

      const response = await fetch("https://api.aiforcetraining.com", {    
       method: "POST",
        headers: {
         "Content-Type": "application/json"
        },
        body: JSON.stringify({
          message: messages,
          currentType,  
          triggerType,  
        })               
      });
      const data = await response.json();
      responseLoading.classList.remove("chatbox--loading");
      submitLoading.forEach(function(item) {
      item.classList.replace("submitdisabled", "submit");  
      });
      submitButton.disabled = false;
      stepBackButton.disabled = false;
      retryButton.disabled = false;
  
      let cleanedMessage = data.completion.content;
      if (cleanedMessage.startsWith("\n\n")) {
        cleanedMessage = cleanedMessage.substring(2);
      }
      setChatLog([...chatLogNew, { user: "AIFORCE", message: cleanedMessage }])
    
      var scrollToTheBottomChatLog = document.getElementsByClassName("chat-log")[0];
      scrollToTheBottomChatLog.scrollTop = scrollToTheBottomChatLog.scrollHeight;
      setChatInput("");
    }
  }
  

  return (
    <div className="App">
      <SideMenu

        newChat={newChat}
        types={types}
        setTypes={setTypes}
        currentType={currentType}
        setCurrentType={setCurrentType}
        triggerType={triggerType}
        setTriggerType={setTriggerType}
        placeholder={placeholder}
        setPlaceholder={setPlaceholder}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
        enterKeyToggle={enterKeyToggle}
        setEnterKeyToggle={setEnterKeyToggle}
      />
      <ChatBox 
        chatInput={chatInput}
        chatLog={chatLog} 
        setChatInput={setChatInput} 
        handleSubmit={handleSubmit}
        handleRetry={handleRetry}
        stepBack={stepBack}
        placeholder={placeholder}
        enterKeyToggle={enterKeyToggle}
        setEnterKeyToggle={setEnterKeyToggle}
       
         />
    </div>
  );
}


export default App;
